import { BaseEntity } from './BaseEntity';
import { Role } from './Role';

export enum AdminStatus {
  ENABLE = 'enable',
  DISABLE = 'disable'
}

export interface Permission extends BaseEntity {
  name: string
  guardName: string
  menu: string
  title: string
  route: string
}

export interface Admin extends BaseEntity {
  username: string
  name: string
  phone: string
  status: AdminStatus
  allocatedAccountCounts: number
  accessToken: string
  tokenType: string
  roles: Role[]
  permissions: Permission[]
}

export interface AdminAllocatedMember {
  username: string
  days: number
  createdAt: string
}

export type PartialAdmin = Partial<Admin>;
